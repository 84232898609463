<template>
  <v-btn
      :ripple="false"
      v-on:click="goto"
      class="nav-button"
      text
    >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'button'
    },
    route_target: {
      type: String,
      default: 'home'
    },
    hash_target: {
      type: String,
      default: ''
    },
    scroll_target: {
      type: String,
      default: ''
    },
    duration: {
      type: Number,
      default: 750
    },
    offset: {
      type: Number,
      default: -65
    },
    easing: {
      type: String,
      default: 'ease-in-out'
    }
  },
  methods: {
    goto: function () {
      this.$root.$emit('navigate', {
        route: this.route_target,
        hash: this.hash_target,
        scroll: this.scroll_target
      }, {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    $btn-hover-opacity:1 !important
</style>
