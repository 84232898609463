<template>
    <v-row class="info-row">
      <v-col cols="3" class="icon-col d-flex justify-center">
        <v-icon
         class="advisor-row-icon"
         >
         {{ icon }}
        </v-icon>
      </v-col>
      <v-col class="info-text-container">
        <v-row class="info-text-header">
          {{ header }}
        </v-row>
        <template v-if="list !== null">
            <v-row>
               <ul class="info-list text">
                  <li
                  v-for="(item, i) in list"
                  :key="i"
                  >{{ item }}
                  </li>
               </ul>
            </v-row>
         </template>
        <template v-if="text !== null">
         <v-row class="info-text text">
            {{ text }}
         </v-row>
        </template>
      </v-col>
      <v-container class="my-n1"><div class="d-flex justify-center pl-13 pr-13"><v-divider color="lightgrey"></v-divider></div></v-container>
    </v-row>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-magnify'
    },
    header: {
      type: String,
      default: 'header'
    },
    text: String,
    list: Array
  }
}
</script>

<style lang="scss">
@import '@/styles/style.scss';

   .advisor-row-icon {
      color: $primary !important;
   }

   .info-text-header {
      color: $primary;

      font-size: 1em;
      font-weight: bold;

      padding-bottom: 4px;
   }

   .info-list {
      list-style-type: none;
      text-align: left;
      padding: 0px !important;
   }

  .text{
    font-size: 0.9em;
  }
</style>
