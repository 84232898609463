<template>
  <div id="concept">
    <v-container class="py-16">
      <v-row class="justify-center text-center">
        <v-col cols="5">
          <v-container
            class="d-inline-flex justify-center my-n16 pb-n12"
            id="whatis-subtitle"
          >
            <v-lazy
              :options="{ threshold: 1 }"
              transition="ease-in-out"
            >
              <h1 class="text-no-wrap font-weight-medium reveal-text">
                Was ist Paradvisor?
              </h1>
            </v-lazy>
          </v-container>
        </v-col>
      </v-row>

      <v-row  class="justify-center text-center">
        <v-col class="pb-10 mt-n3">
            <p>
              Paradvisor bringt auf innovative Art und Weise professionelle und unabhängige Anlageberater <br/>mit Menschen zusammen, die ihr Geld anlegen wollen.
            </p>
        </v-col>
      </v-row>

      <v-row  class="justify-center">
        <v-col cols="9" sm="4">
          <card
            title="Persönlich & Kompetent"
            icon="mdi-calendar-check-outline"
            text="Bei uns sind nur Anlageberater zugelassen, welche die Anforderungen bzgl. Erfahrung und Fachkompetenz nach Schweizer Recht erfüllt haben, d.h. im öffentlichen Beraterregister sind und von Paradvisor verifiziert wurden. Die Anlageberater bieten persönliche Anlagelösungen an, welche auf Ihre Lebensumstände, Bedürfnisse und Ziele zugeschnitten sind."
            :hasIcon="false"
            :isCentered="false"
            :titleIsCentered="false"
          >
          </card>
        </v-col>
        <v-col cols="9" sm="4">
          <card
            title="Einfach & Transparent"
            icon="mdi-calendar-check-outline"
            text="Der ganze Prozess der Anlageberatung verläuft über Paradvisor. Dieser ist schlank und einfach ausgestaltet. Suche, Terminfindung, Durchführung des Beratungsgespräch, Protokollierung und Bezahlung  – alles aus einer Hand und von überall möglich. Daraus ergibt sich eine nie zuvor gesehene Transparenz für unsere Kunden."
            :hasIcon="false"
            :isCentered="false"
            :titleIsCentered="false"
          >
          </card>
        </v-col>
        <v-col cols="9" sm="4">
          <card
            title="Unabhängig & Preiswert"
            icon="mdi-calendar-check-outline"
            text="Auf Paradvisor finden Sie lediglich Anlageberater die frei von Interessenskonflikten sind. Dies garantiert, dass Ihnen nur die besten Anlagelösungen angeboten werden. Da unsere Anlageberater ihre Dienstleistung auf Honorarbasis anbieten, besteht bei ihnen kein Druck «hauseigene» Produkte zu verkaufen. Kosten, die im Zusammenhang mit Produkten und Institutionen (z.B. Banken) entstehen, fallen weg."
            :hasIcon="false"
            :isCentered="false"
            :titleIsCentered="false"
          >
          </card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Card from '@/components/Card'
export default {
  components: {
    Card
  }
}
</script>

<style lang="scss">
@import "@/styles/style.scss";
#whatis {
  width: 100%;
}
#whatis-subtitle{
  color: $primary;
}
</style>
