<template>
  <v-app>
    <Header/>
    <v-main>
    <router-view />
    </v-main>
    <Footer/>
  </v-app>

</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  created () {
    this.$root.$on('navigate', async (target, options) => {
      const currRoute = this.$route.name
      const currHash = this.$route.hash

      if (typeof options === 'undefined') {
        options = {
          duration: 750,
          offset: 0,
          easing: 'ease-in-out'
        }
      }

      var scroll = function () {
        this.$scrollTo('#' + target.scroll, options) // scroll to id
      }

      const cb = scroll.bind(this, target, options)

      console.log(currRoute, currHash, target.hash, target.scroll, target.route)

      // staying on same route
      if (currRoute === target.route) {
        // scroll without changing the current route

        // check if we even need to push a new route
        if (currHash === target.hash) {
          cb()
          return
        }

        // navigate to new hashtag - scroll to on complete
        this.$router.push({
          name: target.route,
          hash: target.hash
        }).then(cb)
      } else {
        options.duration *= 2

        this.$router.push({
          name: target.route,
          hash: target.hash
        }).then(cb)
      }
    })
  }
}
</script>
