<template>
   <div id="information">
      <v-container fill-height id="informationContent" class="py-12">
        <v-row class="justify-center">
          <v-col cols="9">
            <v-container  class="d-inline-flex justify-center my-n12" id="information-subtitle">
              <v-lazy :options="{threshold: 1}" min-height="10vh" transition="ease-in-out">
              <h1 class="text-no-wrap font-weight-medium reveal-text">So funktioniert's</h1></v-lazy>
            </v-container>
          </v-col>

          <v-col cols="9" sm="4" >
            <card
            title="Suchen"
            icon="mdi-account-search-outline"
            text="Suchen Sie sich ganz einfach Ihren persönlichen, professionellen und unabhängigen Anlageberater. Auf Paradvisor sind lediglich geprüfte, im Schweizer Beraterregister zugelassene Anlageberater vorzufinden.">
            </card>
          </v-col>

          <v-col cols="9" sm="4" >
            <card
            title="Termin vereinbaren"
            icon="mdi-calendar-check-outline"
            text="Vereinbaren Sie einen Termin mit Ihrem unabhängigen Anlageberater und lassen Sie sich auf Basis Ihrer persönlichen Lebenslage, Bedürfnisse und  Ziele einen massgeschneiderten Anlagevorschlag unterbreiten.">
            </card>
          </v-col>

          <v-col cols="9" sm="4" >
            <card
            title="Investieren"
            icon="mdi-finance"
            text="Sobald Sie Ihr Einverständnis gegeben haben, legen Sie selbst Ihr Vermögen professionell an. Dabei bleibt stets alles in Ihrem Machtbereich. Nun können Sie sich getrost zurücklehnen, da ab diesem Zeitpunkt Ihr Geld für Sie zu arbeiten beginnt.">
            </card>
          </v-col>

        </v-row>
      </v-container>
   </div>
</template>

<script>
import Card from '@/components/Card'
export default {
  components: {
    Card
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/style.scss';
  #informationContent{
    min-height: 70vh;
  }
  #information-subtitle{
    color: $primary;
  }
</style>
