<template>
  <div>
    <Landing/>
    <component v-bind:is="component" />
    <WhatIs/>
    <v-container id="view-divider"><div class="d-flex justify-center"><v-divider color="lightgrey"></v-divider></div></v-container>
    <Information/>
    <v-container id="view-divider"><div class="d-flex justify-center"><v-divider color="lightgrey"></v-divider></div></v-container>
    <AboutUs/>
    <animatedText/>
  </div>
</template>

<script>
import Landing from './home/Landing'
import Information from './home/Information'
import WhatIs from './home/WhatIs'
import AboutUs from './home/AboutUs'
import animatedText from '@/components/animatedText'

export default {
  name: 'Home',
  data () {
    return {
      component: ''
    }
  },
  components: {
    Landing,
    Information,
    WhatIs,
    AboutUs,
    animatedText
  }
}
</script>
<style lang="scss">
#view-divider{
  width: 20vw;
}
</style>
