import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMq from 'vue-mq'

import Vuex from 'vuex'
import { advisors } from '@/store/advisors.js'

import scrollto from 'vue-scrollto'

Vue.config.productionTip = false
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    account: {
      namespaced: true,
      state: {
        user: {
          username: 'example',
          fullName: 'Max Mustermann'
        }
      }
    },
    advisors: advisors
  }
})

Vue.use(scrollto)
Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 1250,
    lg: Infinity
  }
})

new Vue({
  router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
