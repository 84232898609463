import { render, staticRenderFns } from "./Advisors.vue?vue&type=template&id=1a5dd497&scoped=true&"
import script from "./Advisors.vue?vue&type=script&lang=js&"
export * from "./Advisors.vue?vue&type=script&lang=js&"
import style0 from "./Advisors.vue?vue&type=style&index=0&id=1a5dd497&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a5dd497",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VChip,VChipGroup,VCol,VContainer,VRow})
