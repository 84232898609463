<template>
  <v-hover v-slot="{ hover }" close-delay="500">
    <v-card
    :elevation="hover ? 18 : 6"
    class="transition-swing"
    rounded="lg"
    id="BaseCard"
    height="100%"
    >
    <v-container class="justify-center" :class="hasIcon ? 'd-inline-flex' : 'd-none'">
      <v-card
      rounded="circle"
      :elevation="hover ? 6 : 0"
      class="transition-swing d-inline-flex"
      id="circleCard"
      v-on:click="goto('advisors', '', 'advisorsContent')"
      >
        <v-icon
        id="cardIcon"
        class="pa-3"
        size="77px"
        align>
        {{ icon }}
        </v-icon>
      </v-card>
    </v-container>
        <v-card-title :class="titleIsCentered ? 'justify-center' : ''">
            {{ title }}
        </v-card-title>
        <v-card-text>
            <p :class="isCentered ? 'text-center' : ''">
                {{ text }}
            </p>
        </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et doloremagna aliquyam erat, sed diam voluptua.'
    },
    title: {
      type: String,
      default: 'keyword'
    },
    icon: {
      type: String,
      default: 'mdi-magnify'
    },
    hasIcon: {
      type: Boolean,
      default: true
    },
    isCentered: {
      type: Boolean,
      default: true
    },
    titleIsCentered: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goto: function (route, hash, scroll) {
      this.$root.$emit('navigate', {
        route: route,
        hash: hash,
        scroll: scroll
      }, {
        duration: 750,
        offset: 0,
        easing: 'ease-in-out'
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/style.scss';
#BaseCard {
  opacity: 0.9
}
#cardIcon{
    color:$primary;// rgba(255,255,255,0.075)
  }
#BaseCard:hover{
  opacity: 1;
  #circleCard{
    background-color:$primary;// rgba(255,255,255,0.075)
  }
  #cardIcon{
    color: white;
  }
}

</style>
