import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

import WhatIs from '../views/home/WhatIs'
import Information from '../views/home/Information'
import AboutUs from '../views/home/AboutUs'
import NotFound from '../views/NotFound'

import Advisors from '../views/Advisors'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Paradvisor - Ihre persönliche Anlageberatung',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/advisors',
    name: 'advisors',
    component: Advisors,
    meta: {
      title: 'Paradvisor - Ihren Anlageberater finden',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/',
    name: 'concept',
    component: WhatIs
  },
  {
    path: '/',
    name: 'information',
    component: Information
  },
  {
    path: '/',
    name: 'about',
    component: AboutUs
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }

  /*
    TODO: Enable lazy-loading
    route level code-splitting
    this generates a separate chunk (about.[hash].js) for this route
    which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" '../views/About.vue')
    */
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  }

  if (to.path === from.path) {
    return
  }
  // start at the top of the page if changed to a new route
  return { x: 0, y: 0 }

  /*
  if (to.hash) {
    return { selector: to.hash }
  } else {
    return { x: 0, y: 0 }
  }
  */
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  base: process.env.BASE_URL,
  routes
})

export default router
