<template>
    <v-app-bar
      app
      id="navBar"
      dark
      :inverted-scroll="invscroll"
      scroll-threshold="200"
    > <!-- key forces a rerendering of component if key changes so scroll-threshold updated properly -->
      <div class="d-flex align-center">
        <span>
          <a v-on:click="logofunction"><h2 class="header-title-text">PARADVISOR</h2></a>
        </span>
      </div>

      <v-spacer></v-spacer>

      <div class="hidden-sm-and-down">
        <ul>
          <li>
          <a v-for="(item, i) in items"
            :key="i"
            class="header-button"
            >
            <nav-button
              :label="item.label"
              :route_target="item.route"
              :hash_target="item.hash"
              :scroll_target="item.scroll"
            ></nav-button>
          </a>
          </li>
        </ul>
      </div>

      <div class="hidden-md-and-up">
        <v-menu
          open-on-hover
          offset-y
          close-on-content-click
          transition="slide-y-transition"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              depressed
              class="header-menu-button"
            >
              <v-icon>
                mdi-menu
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
            <nav-button
              :label="item.label"
              :route_target="item.route"
              :hash_target="item.hash"
              :scroll_target="item.scroll"
            ></nav-button>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
</template>

<script>
import NavButton from './NavButton'

export default {
  components: {
    NavButton
  },
  data: () => {
    return {
      items: [
        {
          label: 'Finde deinen Berater',
          route: 'advisors',
          hash: '',
          scroll: 'advisorsContent'
        },
        {
          label: 'Die Vorteile',
          route: 'home',
          hash: '#concept',
          scroll: 'concept'
        },
        {
          label: 'So funktioniert\'s',
          route: 'home',
          hash: '#information',
          scroll: 'information'
        },
        {
          label: 'Über uns',
          route: 'home',
          hash: '#about',
          scroll: 'about'
        }],
      invscroll: true
    }
  },
  created () {
    this.invscroll = (this.$route.name === 'home')
  },
  methods: {
    logofunction: function () {
      this.$root.$emit('navigate', {
        route: 'home',
        hash: '',
        scroll: 'landing'
      }, {
        duration: 900,
        offset: 0,
        easing: 'ease-in-out'
      })
    }
  },
  watch: {
    $route (to) {
      this.invscroll = !(to.name === 'advisors')
    }
  }
}
</script>

<style lang="scss" scoped>

@import '@/styles/style.scss';

ul {
  margin-right: 5vw;
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
}
li {
  display: table-cell;
  position: relative;
  margin: 0px auto 0;
}
.header-button {
  display: inline-block;

  font-size: 1rem;
  text-decoration: none;
  letter-spacing: 0.15em;

  padding: .0rem 0;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  position: relative;
}
.header-button:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background:white;
  transition: width 0.4s ease 0s, left 0.4s ease 0s;
  width: 0%;
}
.header-button:hover:after {
  width: 100%;
  left: 0;
}
#navBar{
  background-color: rgba($primary, 0.9);
  height: 13vh;
  width: 100vw;
}

.header-menu-button {
  color: white;
}

.logo-color {
  // get the filter for the color at https://codepen.io/sosuke/pen/Pjoqqp
  //filter: invert(98%) sepia(95%) saturate(17%) hue-rotate(292deg) brightness(105%) contrast(100%);
}

.header-title-text {
  padding-left: 10px;
  font-family: "Playfair Display", serif;
  font-weight: 300;
  letter-spacing: 0.5em;
  color: #fff;
}

</style>
