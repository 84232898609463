<template>
  <v-row justify="center">
    <v-col
      cols="12"
    >
      <div class="pa-4">
        <v-chip-group
          active-class="primary--text"
          v-model="selected"
          column
          multiple
        >
          <v-chip
            filter
            v-for="opt in options"
            :key="opt"
          >
            {{ opt }}
          </v-chip>
        </v-chip-group>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    name: String,
    options: Array
  },
  data () {
    return {
      selected: this.$store.state.advisors.filters[this.name].map(i => this.options.indexOf(i))
    }
  },
  methods: {
    fetch: function () {
      return this.selected.map(i => this.options[i])
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
