<template>
  <div id="about">
    <v-container fill-height id="aboutContent">
      <v-row class="justify-center align-self-end">
        <v-col cols="9">
          <v-container class="d-inline-flex justify-center mb-n12" id="aboutus-title">
            <h3 class="font-weight-thin">paradvisor.ch</h3>
          </v-container>
        </v-col>

        <v-col cols="9">
          <v-container class="d-inline-flex justify-center my-n12" id="aboutus-subtitle">
            <v-lazy :options="{threshold: 1}" min-height="10vh" transition="ease-in-out">
            <h1 class="font-weight-medium reveal-text">Unser Team</h1></v-lazy>
          </v-container>

        </v-col>
      </v-row>
      <v-row class="justify-center align-self-start">
        <v-col cols="11" sm="6" md="5" lg="5">
          <v-card id="about-us-img" elevation="6" rounded="lg">
            <v-img
            id="betimImage"
               max-height="60vh"
               min-height="30vh"
               position="23% 10%"
               src="../../assets/betimng.jpg"
               lazy-src="../../assets/betimng_small.jpg"
            ></v-img>
          </v-card>
        </v-col>

        <v-col cols="11" md="6" >
          <v-hover v-slot="{ hover }" close-delay="700">
            <v-card
              id="aboutUs"
              :elevation="hover ? 18 : 6"
              :class="$vuetify.breakpoint.smAndDown ? 'sm' : ''"
              class="transition-swing"
              rounded="lg"
              height="100%"
            >
              <v-card-title class="d-inline-flex">
                {{ title }}
              </v-card-title>
              <v-card-text>
                <p>
                  {{ text }}
                </p>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default:
        'Schon während des Studiums an der Universität St.Gallen (HSG) faszinierten mich Themen rund um Finanzen und Anlagen. In praktischer Hinsicht arbeitete ich während, als auch nach dem Studium, bei renommierten Schweizer Privatbanken wie Vontobel und Pictet. Bei letzterer betreute und beriet ich sehr vermögende Privatkunden. Ausbildungstechnisch habe ich die international anerkannte Ausbildung CFA in Angriff genommen und das Diplom CWMA erlangt, welches ein internationales Gütesiegel in der Vermögensverwaltung ist. Während der Zeit bei der Privatbank beschäftigte mich die Frage, ob man die gleiche Professionalität, Qualität und Exzellenz der Anlageberatung auch Personen anbieten kann, die nicht über mehrere hundert Millionen verfügen. Hier kristallisierte sich allmählich die Idee heraus, Paradvisor zu gründen. Dies geschah mit dem Ziel jedem Zugang zu unabhängiger, kompetenter und persönlicher Anlageberatungen zu ermöglichen. Seitdem habe ich folgende Vision: Die Anlageberatung zu demokratisieren und allen Zugang zu exzellenten und transparenten Dienstleistungen zu fairen Preisen zu ermöglichen.'
    },
    title: {
      type: String,
      default: 'Betim Bunjaku - Founder & CEO'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/style.scss';
#aboutContent {
  min-height: 100vh;
}
#aboutUs:hover {
  //transform: translateX(-30px) scale(1.046);
  //&.sm{ //overrides the transform if it matches the attached class ->  :class="($vuetify.breakpoint.smAndDown) ? 'sm' : ''"
  //  transform: translateY(-40px);
  // }
}

#aboutus-subtitle{
   color: $primary;
}
</style>
