<template>
  <v-hover v-slot="{ hover }">
    <v-card
    :elevation="hover&&overlay==false ? 12 : 6"
    :style="hover&&overlay==false ? {opacity: 1} : {opacity: 0.9}"
    class="transition-swing"
    rounded="lg"
    height="100%"
    max-width="350px"
    min-width="275px"
    >

    <v-overlay :absolute="true" :value="overlay"></v-overlay>
    <v-container fill-height>
      <v-row class="header-row">
        <v-col>
          <v-row class="name-row">
          {{ name }}
          </v-row>
          <v-row class="qualification-row">
          {{ qualificationTitle }} an der {{ school }}
          </v-row>
        </v-col>
        <v-divider class="mr-4" vertical color="lightgrey"> </v-divider>
        <v-col cols="4">
          <v-row class="rate-row">
            {{ rate }}.-
            <span class="rate-subtext">  /h</span>
          </v-row>
          <v-row class="rating-row">
            <v-icon
              class="rating-active"
              v-for="n in rating" :key="n"
              size="inherit"
              color="black"
            >mdi-star</v-icon>
            <v-icon
              class="rating-inactive"
              v-for="i in 5-rating" :key="i+5"
              size="inherit"
              color="grey"
            >mdi-star</v-icon>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="image-row">
        <!-- TODO: good image scale & dynamic image binding -->
        <v-col cols="12" class="px-0 py-0 mb-4">
          <v-img
            max-width="100%"
            max-height="100%"
            :aspect-ratio="4/2.5"
            :src="getImgUrl(image)"
            class="align-end"
          >
            <v-row class="d-flex justify-center mb-n1">
              <v-col cols="10" class="px-0">
                <v-card rounded="lg" class="px-2" style="opacity:0.8" elevation="3">
                  {{ catchPhrase }}
                </v-card>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
      <AdvisorInfoRow
        v-if="specialization !== undefined"
        icon="mdi-check"
        header="Spezialisierung"
        :list="specialization"
      />
      <!--<AdvisorInfoRow
        icon="mdi-school"
        header="Ausbildung"
        :text="school"
      />-->
      <AdvisorInfoRow
        icon="mdi-clock-time-eight-outline"
        header="Erfahrung"
        :text="experience + ' Jahre'"
      />
      <AdvisorInfoRow
        icon="mdi-home"
        header="Standort"
        :text="plz + ' ' + city"
      />
      <v-row class="footer-row" >
        <v-col cols="9" class="footer-text">
          <v-btn
          :ripple="false"
          href="mailto:info@paradvisor.ch"
          class="schedule-button ml-2"
          text
          rounded
          >
          kontaktieren
          </v-btn>
        </v-col>
        <v-col cols="3" class="align-self-center">
            <v-icon class="footer-icon"
            >
              mdi-bookmark
            </v-icon>
        </v-col>
      </v-row>
    </v-container>
    </v-card>
  </v-hover>
</template>

<script>
import AdvisorInfoRow from '@/components/AdvisorInfoRow'

export default {
  props: {
    name: {
      type: String,
      default: 'Betim Bunjaku'
    },
    qualificationTitle: {
      type: String,
      default: 'M.A HSG'
    },
    rate: {
      type: Number,
      default: 299
    },
    rating: {
      type: Number,
      default: 3
    },
    catchPhrase: {
      type: String,
      default: 'Anlagespezialist für die gesamte Vermögensallokation.'
    },
    image: {
      type: String,
      default: 'betimng.jpg'
    },
    specialization: Array,
    school: {
      type: String,
      default: 'Universität St. Gallen'
    },
    experience: {
      type: Number,
      default: 10
    },
    plz: {
      type: Number,
      default: 8000
    },
    city: {
      type: String,
      default: '8610 Uster'
    },
    overlay: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openEmail: function () {
      console.log('Send me an email!')
    },
    getImgUrl (pic) {
      try {
        return require('@/assets/' + pic)
      } catch (e) {
        return require('@/assets/avatar.jpg')
      }
    }
  },
  components: {
    AdvisorInfoRow
  }
}
</script>

<style lang="scss">
@import '@/styles/style.scss';
#BaseCard {
  //opacity: 0.9
}
#BaseCard:hover{
  //opacity: 1;
}

  .header-row {
    margin: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .name-row {
    color: $primary;

    font-size: 1.2em;
    font-weight: bold;
  }

  .rate-row {
    padding-left: 3px;
    font-size: 1.2em;
    color: green;
  }

  .rate-subtext {
    font-size: 0.9em;
    color: black;
  }
  .rating-row {
    padding-top: 4px;
  }

  .footer-row {
    margin: 0px;
  }

  .footer-icon {
    color: green;
    text-align: center;
    vertical-align: middle !important;

    &:hover {
      color: $primary;
    }
  }

  .schedule-button {
    color: white !important;
    background-color: $primary;

    &:hover {
      color: $primary;

    }
}
</style>
