<template>
  <div id="advisors">
    <v-container id="advisorsContent" class="py-12 px-16" fluid :class="$vuetify.breakpoint.mdAndDown ? 'md' : ''">
      <v-row  class="justify-center text-center">
        <v-col class="mt-n3">
            <h2>
              Ganz einfach einen Berater auswählen und direkt kontaktieren.
            </h2>
        </v-col>
      </v-row>
      <v-row class="justify-center text-center ma-12">
        <div id="advisorsDetailed">
          <div v-if="!showDetailed">
            <v-btn
              @click="showDetailed = true"
              x-large
              outlined
              id="btn"
            >
              Detaillierte Suche
            </v-btn>
            </div>
          <div v-if="showDetailed">
            <advisors-inputs
              :categories=categories
            />
          </div>
        </div>
      </v-row>

      <div v-if="!showDetailed" class="advisorCards">
        <v-row class="pl-4 justify-center">
        <div>
          <v-chip-group
            active-class="primary--text"
            v-model="sorting.selected"
            column
          >
            <v-chip
              v-for="sort in sorting.options"
              :key="sort"
            >
              {{ sort }}
            </v-chip>
          </v-chip-group>
        </div>
        </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="advisor-card-box d-flex justify-center my-12"
          v-for="item in showingAdvisors"
          :key="item.id"
        >
          <advisor-card
            :name="item.name"
            :qualificationTitle="item.qualificationTitle"
            :rate="item.rate"
            :rating="item.rating"
            :image="item.image"
            :catchPhrase="item.catchPhrase"
            :specialization="[item.specialization1, item.specialization2, item.specialization3]"
            :school="item.school"
            :experience="item.experience"
            :plz="item.plz"
            :city="item.city"
          >
          </advisor-card>
        </v-col>
      </v-row>
    </div>
    </v-container>

    <v-alert type="info" class="infoAlert transition-swing" dismissible>
      Unsicher? Jetzt <a href="mailto:info@paradvisor.ch">kostenloses Erstgespräch</a> für die Analyse Ihrer Vermögenssituation mit Paradvisor vereinbaren. Willst du auch Berater werden? Melde dich <a href="mailto:info@paradvisor.ch">hier.</a>
    </v-alert>
  </div>
</template>

<script>
import AdvisorsInputs from '@/components/AdvisorsInputs'
import AdvisorCard from '@/components/AdvisorCard'

// import { advisorsDB } from '@/database/firebase.js'

import { mapState } from 'vuex'

export default {
  components: {
    AdvisorsInputs,
    AdvisorCard
  },
  created () {
    /*
    // only retrieve 10 items
    const snapshot = await advisorsDB.limit(10).get()
    snapshot.forEach(doc => {
      this.advisors.push(doc.data())
      this.fillCategories(doc.data())
    })
    */
  },
  data () {
    return {
      showDetailed: false
    }
  },
  computed: {
    ...mapState('advisors', {
      advisors: (state) => state.db,
      categories: (state) => state.categories,
      filter: (state) => state.filters,
      sorting: (state) => state.sorting
    }),
    showingAdvisors () {
      let showing = []
      let sortby = this.sorting.options[this.sorting.selected]
      let second = '-'

      // true for ascending
      let sortbyOrder = false
      let secondOrder = false

      const f = this.filter
      if (this.filter.active) {
        this.advisors.forEach(function (adv) {
          if (f.rating <= adv.rating) {
            if (f.price[0] <= adv.rate && adv.rate <= f.price[1]) {
              if (
                f.experience[0] <= adv.experience &&
                adv.experience <= f.experience[1]
              ) {
                if (f.cities.length === 0 || f.cities.includes(adv.city)) {
                  if (
                    f.qualifications.length === 0 ||
                    f.qualifications.includes(adv.qualificationTitle)
                  ) {
                    if (
                      f.schools.length === 0 ||
                      f.schools.includes(adv.school)
                    ) {
                      if (f.specializations.length === 0) {
                        showing.push(adv)
                      } else {
                        let matches = 0
                        if (f.specializations.includes(adv.specialization1)) {
                          matches += 3
                        }
                        if (f.specializations.includes(adv.specialization2)) {
                          matches += 2
                        }
                        if (f.specializations.includes(adv.specialization3)) {
                          matches += 1
                        }
                        if (matches > 0) {
                          adv.recscore = matches
                          showing.push(adv)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        })
      } else {
        showing = this.advisors
      }

      switch (sortby) {
        case 'Preis': {
          sortby = 'rate'
          second = (this.filter.specializations.length > 0) ? 'recscore' : 'rating'
          sortbyOrder = true
          secondOrder = false
          break
        }
        case 'Bewertung': {
          sortby = 'rating'
          second = (this.filter.specializations.length > 0) ? 'recscore' : 'rate'
          sortbyOrder = false
          secondOrder = !(this.filter.specializations.length > 0)
          break
        }
        case 'Erfahrung': {
          sortby = 'experience'
          second = (this.filter.specializations.length > 0) ? 'recscore' : 'rating'
          sortbyOrder = false
          secondOrder = false
          break
        }
        case 'Spezialisierungs Matching': {
          sortby = 'recscore'
          second = 'rating'
          sortbyOrder = false
          secondOrder = false
          break
        }
        default:
          console.log('wrong sort option')
          sortby = 'rating'
      }

      console.log(sortby, second)

      const compare = (a, b) => {
        if (a[sortby] > b[sortby]) {
          return sortbyOrder ? 1 : -1
        }
        if (a[sortby] < b[sortby]) {
          return sortbyOrder ? -1 : 1
        }
        if (a[second] > b[second]) {
          return secondOrder ? 1 : -1
        }
        if (a[second] < b[second]) {
          return secondOrder ? -1 : 1
        }
        return 0
      }

      showing.sort(compare)
      return showing.slice(0, 20)
    }
  },
  methods: {
    disableDetailed () {
      this.showDetailed = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";

#advisorsContent {
  &.md {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
#advisors-subtitle {
  color: $primary;
}

.infoAlert {
  position: fixed !important;
  bottom: 0px !important;
  left: 0px !important;
  margin-bottom: 0px !important;
  width: 100%;
  z-index: 5;
}
a {
  color: rgb(162, 226, 255) !important;
}
</style>
