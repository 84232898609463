<template>
  <v-container fill-height>
    <v-row class="inputs">
      <v-col class="mb-4 justify-left" cols="12" md="7" sm="12">
        <v-row>
          Preis pro Stunde:
          {{ price[0] }} - {{ price[1] }}.- /h
        </v-row>
        <v-row>
          <v-range-slider
            hide-details
            v-model="price"
            :min="categories.rate[0]"
            :max="categories.rate[1]"
          />
        </v-row>
      </v-col>
      <v-col class="mb-4 justify-left" cols="12" md="7" sm="12">
        <v-row>
        Bewertung
        </v-row>
        <v-row>
          <v-rating
            v-model="rating"
            empty-icon="mdi-star"
            full-icon="mdi-star"
            half-icon="mdi-star-half-full"
            hover
            length=5
            background-color="grey"
          ></v-rating>
        </v-row>
      </v-col>
      <v-col class="mb-4 justify-left" cols="12" md="7" sm="12">
        <v-row>
        Erfahrung:
        {{ experience[0] }}-{{ experience[1] }} Jahre
        </v-row>
        <v-row>
          <v-range-slider
            hide-details
            v-model=experience
            :min=categories.experience[0]
            :max=categories.experience[1]
          />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="my-4"></v-row>

    <v-row class="locations">
      <v-col>
        <v-row>
          Standort
          <!-- (Nice to have: map of switzerland where you can select the region you want) -->
        </v-row>
        <v-row>
          <CheckBoxgrid
             name="cities"
             ref="cities"
            :options="categories.cities"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-3"></v-row>
    <v-row class="specializations">
      <v-col>
       <v-row>
       Spezialisierung
       </v-row>
       <v-row>
         <CheckBoxgrid
            name="specializations"
            ref="specializations"
           :options="categories.specializations"
         />
       </v-row>
      </v-col>
    </v-row>
    <v-row class="my-3"></v-row>
    <v-row class="qualifications">
      <v-col>
        <v-row>
          Qualifikation
        </v-row>
        <v-row>
         <CheckBoxgrid
            name="qualifications"
            ref="qualifications"
           :options="categories.qualifications"
         />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-3"></v-row>
    <v-row class="education">
      <v-col>
        <v-row>
          Ausbildung
        </v-row>
        <v-row>
          <CheckBoxgrid
             name="schools"
             ref="schools"
            :options="categories.schools"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-3"></v-row>
    <v-btn
      x-large
      outlined
      id="btn"
      @click="commit"
    > Berater finden
    </v-btn>
  </v-container>
</template>

<script>
import CheckBoxgrid from '@/components/inputs/CheckboxGrid'

export default {
  components: {
    CheckBoxgrid
  },
  props: {
    categories: Object
  },
  data () {
    return {
      price: this.$store.state.advisors.filters.price,
      experience: this.$store.state.advisors.filters.experience,
      rating: this.$store.state.advisors.filters.rating
    }
  },
  methods: {
    commit: function () {
      this.commitFilters()
      this.$parent.disableDetailed()
    },
    commitFilters: function () {
      const c = this.$refs.cities.fetch()
      const s = this.$refs.specializations.fetch()
      const q = this.$refs.qualifications.fetch()
      const sch = this.$refs.schools.fetch()

      let active = true
      if (c.length === 0 && s.length === 0 && q.length === 0 && sch.length === 0) {
        const pc = this.$store.state.advisors.categories.rate
        const ec = this.$store.state.advisors.categories.experience
        if (this.price[0] === pc[0] && this.price[1] === pc[1] && this.experience[0] === ec[0] && this.experience[1] === ec[1] && this.rating === 0) {
          active = false
        }
      }

      this.$store.commit('advisors/updateFilters', {
        active: active,
        price: this.price,
        rating: this.rating,
        experience: this.experience,
        cities: c,
        specializations: s,
        qualifications: q,
        schools: sch
      })

      if (active && s.length > 0) {
        this.$store.commit('advisors/updateSortOptions', {
          action: 'add',
          which: 'Spezialisierungs Matching'
        })
      } else {
        this.$store.commit('advisors/updateSortOptions', {
          action: 'remove',
          which: 'Spezialisierungs Matching'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/style.scss';

.test {
  color: $primary;
}

</style>
