require('./overrides.sass')

const preset = {
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#0020a0',
        secondary: '#968ab6',
        tertiary: '#fcf7ff',
        quaternary: '#e8d5b5'
      },
      light: {
        primary: '#0020a0',
        secondary: '#968ab6',
        tertiary: '#fcf7ff',
        quaternary: '#e8d5b5'
      }
    }
  }
}

module.exports = { preset }
