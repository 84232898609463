import db from '@/database/mock.json'

const data = []
for (const key in db) {
  data[key] = db[key]
}

var categories = {
  specializations: [],
  schools: [],
  qualifications: [],
  rate: [10000, -1], // only saves min & max
  experience: [100, -1], // only saves min & max
  cities: []
}

const add = (x, xs, validation = '') => {
  if (x !== validation && !xs.includes(x)) {
    xs.push(x)
  }
}

const minmax = (x, minmax) => {
  if (x < minmax[0]) {
    minmax[0] = x
  } else if (x > minmax[1]) {
    minmax[1] = x
  }
}

for (const row of Object.values(data)) {
  for (const [prop, item] of Object.entries(row)) {
    switch (prop) {
      case 'specialization1': add(item, categories.specializations); break
      case 'specialization2': add(item, categories.specializations); break
      case 'specialization3': add(item, categories.specializations); break
      case 'school': add(item, categories.schools); break
      case 'city': add(item, categories.cities); break
      case 'qualificationTitle': add(item, categories.qualifications); break
      case 'rate': minmax(item, categories.rate); break
      case 'experience': minmax(item, categories.experience); break
    }
  }
}

categories.cities = categories.cities.sort()
categories.specializations = categories.specializations.sort()
categories.qualifications = categories.qualifications.sort()
categories.schools = categories.schools.sort()

export const advisors = {
  namespaced: true,
  state: {
    db: data,
    categories: categories,
    filters: {
      active: false,
      price: categories.rate,
      rating: 0,
      experience: categories.experience,
      cities: [],
      specializations: [],
      qualifications: [],
      schools: []
    },
    sorting: {
      options: ['Preis', 'Bewertung', 'Erfahrung'],
      selected: 1
    }
  },
  mutations: {
    updateAdvisors (state, payload) {
      for (const { id, ...data } in payload) {
        console.log('adding to db', id, data)
        state.db[id] = data
      }
    },
    updateFilters (state, payload) {
      for (const [key, item] of Object.entries(payload)) {
        if (key in state.filters) {
          state.filters[key] = item
        } else {
          console.log('faulty advisor filter state update:', key, payload)
        }
      }
    },
    updateSortOptions (state, payload) {
      if (payload.action === 'add' && !state.sorting.options.includes(payload.which)) {
        state.sorting.options.push(payload.which)
      } else if (payload.action === 'remove' && state.sorting.options.includes(payload.which)) {
        if (state.sorting.selected === state.sorting.options.length - 1) {
          state.sorting.selected = 1
        }
        state.sorting.options.pop(payload.which)
      }
    }
  }
}
